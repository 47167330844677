// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { fetchParentStudents, impersonateStudent } from 'modules/parent';
import { getParentId } from 'selectors/auth';
import { getStudentsForCombo } from 'selectors/parent';
import noop from 'utils/noop';
import ls from 'utils/localStorage';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import ComboBox from 'components/ComboBoxNeon';
import s from '../../styles.scss';

type Props = {
  students: Object[],
  selection: number,
  impersonateStudent: Function,
  fetchParentStudents: Function,
  parentId: number,
  students: Object[],
};
@connect(
  (state) => ({
    parentId: getParentId(state),
    students: getStudentsForCombo(state),
  }),
  {
    fetchParentStudents,
    impersonateStudent,
  }
)
export default class StudentsDropdown extends React.Component<Props> {
  static defaultProps = {
    fetchParentStudents: noop,
    impersonateStudent: noop,
    parentId: 0,
    students: [],
  };

  UNSAFE_componentWillMount() {
    this.props.fetchParentStudents(this.props.parentId);
  }

  onSelect = (value: string) => {
    this.props.impersonateStudent(value);
  };

  render() {
    const { students, selection } = this.props;

    // Deep linking for unified portal parent flow
    const urlForDeepLinking = location.pathname + location.search;
    const [url, queryString = ''] = urlForDeepLinking.split('?');
    const params = queryString.split('&');
    const queryParams = {};
    params.forEach((param) => {
      const [key, value] = param.split('=');
      if (value) queryParams[key] = value;
    });
    const studentId = ls.getItem('studentId') || queryParams.studentid;
    if (
      location.pathname !== '/select-child' &&
      Array.isArray(students) &&
      students.length &&
      studentId
    ) {
      const parentStudent = students.find((student) => student.value === Number(studentId));
      ls.removeItem('studentId');
      if (parentStudent && selection && parentStudent.value !== selection) {
        if (queryParams.studentid) {
          delete queryParams.studentid;
        }
        if (queryParams.hssignin) {
          delete queryParams.hssignin;
        }
        const modifiedQueryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join('&');
        const parentUrlDeepLinkUrl = modifiedQueryString ? `${url}?${modifiedQueryString}` : url;
        this.props.impersonateStudent(parentStudent.value, parentUrlDeepLinkUrl);
        return <LoadingPlaceholder />;
      }
    }

    return (
      <ComboBox
        label="Switch Child:"
        options={students}
        onSelect={this.onSelect}
        noDefault
        invertColor
        inline
        value={selection}
        labelProp="label"
        valueProp="value"
        selectClassName={s.selectCls}
        labelClassName={s.labelCls}
      />
    );
  }
}
