import React from 'react';
import { connect } from 'utils/connect';
import { getCurrentUserData } from 'modules/auth';
import { getAuth, userLoaded } from 'selectors/auth';
import { load as loadHighschool } from 'modules/highschool';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getHighSchool, isHighSchoolLoaded } from 'selectors/highschool';

import { initGainsightAnalytics } from '../../utils/gainsightAnalytics';

function tryInitGainsightAnalytics(...args) {
  try {
    initGainsightAnalytics(...args);
  } catch (err) {
    console.error(`Gainsight initialization failed: ${err.message}`);
  }
}

export default function GainsightAnalytics({ authenticated }) {
  const store = useStore();
  const dispatch = useDispatch();
  const enableSimplifiedConfig = false;
  const loaded = useSelector((state) => userLoaded(state) && isHighSchoolLoaded(state));

  React.useEffect(() => {
    if (!authenticated) {
      if (enableSimplifiedConfig) {
        tryInitGainsightAnalytics(null, null, true);
      } else {
        tryInitGainsightAnalytics();
      }
    } else {
      dispatch(getCurrentUserData());
      dispatch(loadHighschool());
    }
  }, [authenticated]);

  React.useEffect(() => {
    if (authenticated && loaded) {
      const state = store.getState();
      if (enableSimplifiedConfig) {
        tryInitGainsightAnalytics(getAuth(state), getHighSchool(state), true);
      } else {
        tryInitGainsightAnalytics(getAuth(state), getHighSchool(state));
      }
    }
  }, [authenticated, loaded, store]);

  return null;
}

@connect(() => ({}), {
  getCurrentUserData,
  loadHighschool,
})
export class LegacyGainsightAnalytics extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      Promise.all([this.props.getCurrentUserData(), this.props.loadHighschool()]).then(
        ([currentUser, highschool]) => {
          initGainsightAnalytics(currentUser, highschool);
        }
      );
    }, 1);
  }

  render() {
    return null;
  }
}
