import withGlobals from 'components/Globals';
import { connect } from 'react-redux';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { aceChatbotUiUrl, dsApiUrl, dsApiTempToken } from 'config/api';
import { AceChatbot, AceChatbotProps } from './components/AceChatbot';
import { getFeatureFlags } from 'selectors/featureFlags';
import { FeatureFlags } from 'types/featureFlags';
import session from '../../../config/session';
import uuid from 'uuid';

const translations = {
  locales: {
    en: {
      'ai-chat-bot.app_name': 'Ace',
      'ai-chat-bot.show_facts_tooltip': 'Show me facts about this topic',
      'ai-chat-bot.simplfy_tooltip': 'Simplify the topic',
      'ai-chat-bot.challenge_me_tooltip': 'Challenge me',
      'ai-chat-bot.step_by_step_tooltip': 'Step-by-step guide',
      'ai-chat-bot.history_tooltip': 'Share some history about this topic',
      'ai-chat-bot.show_facts_message': 'Show me fun facts about this topic.',
      'ai-chat-bot.simplfy_message': 'Simplify the topic for me.',
      'ai-chat-bot.challenge_me_message': 'Challenge me on this topic.',
      'ai-chat-bot.step_by_step_message': 'Show me a step-by-step guide.',
      'ai-chat-bot.history_message':
        'Share the history and some little-known facts about this topic.',
      'ai-chat-bot.submit_text': 'Send Message',
      'ai-chat-bot.chat_input_message_placeholder':
        'Enter your message. Do not share personal data.',
      'ai-chat-bot.waiting_for_response_tooltip': 'Waiting for a response.',
      'ai-chat-bot.api_error_content_message':
        'Something went wrong when I tried to answer you. Try again later.',
      'ai-chat-bot.currently_unavailable':
        "I can't respond right now. Try again in a few minutes.",
      'ai-chat-bot.invalid_configuration': 'Invalid configuration details entered.',
      'ai-chat-bot.forbidden_topic': 'I cannot talk about that subject.',
      'ai-chat-bot.initial_message_content':
        "Now profile is changed and now Hi, I'm {{studentName}}.",
      'ai-chat-bot.parse_error_message':
        'Something went wrong when I tried to answer you. Try again.',
      'ai-chat-bot.api_error_content_suggestions': 'Retry',
      'ai-chat-bot.broken_image': 'The image link is broken and cannot be displayed.',
      'ai-chat-bot.broken_video': 'The video link is broken and cannot be displayed.',
    },
  },
};

interface AceAppProps extends RouteComponentProps {
  scriptsLoaded: boolean;
  history: any;
  featureFlags: FeatureFlags;
}

const AceAppWrapper: React.FunctionComponent<AceAppProps> = ({ featureFlags }) => {
  const { isValid, data } = session;
  const enablePowerbuddy = featureFlags.featureNavianceStudentEnablePowerbuddy === true;

  const userProfile = {
    ...data,
  };
  const { fullName, grade, id, highSchoolId, districtId } = userProfile;
  const inputProps: AceChatbotProps = {
    districtId: `${districtId}-${highSchoolId}`,
    buildingId: '12345',
    studentId: `${id}`,
    studentName: fullName,
    gradeLevel: `${grade}`,
    mfeUrl: aceChatbotUiUrl,
    mfeApiPrefix: dsApiUrl,
    productCode: 'SGY',
    sessionId: `${districtId}-${highSchoolId}-${id}-${uuid.v4()}`,
    topic: 'Exploring colleges for programs in Artificial Intelligence',
    translations: translations.locales.en,
    language: 'en-US',
    authToken: dsApiTempToken,
  };

  return <div>{enablePowerbuddy && <AceChatbot {...inputProps} />}</div>;
};

export default withGlobals(
  connect((state) => {
    return {
      featureFlags: getFeatureFlags(state),
    };
  })(withRouter(AceAppWrapper))
);
