import * as React from 'react';
import cx from 'classnames';
import Icon from 'components/Icon';
import Button from 'components/Button';
import s from './styles.scss';

export type HeaderType =
  | 'dark'
  | 'green'
  | 'warning'
  | 'alert'
  | 'smalldark'
  | 'midnight'
  | 'grey'
  | 'lightgrey'
  | 'goal'
  | 'general'
  | 'lightpurple'
  | 'fuschia'
  | 'purple'
  | 'white';

interface Props {
  type: HeaderType;
  title: PageTitle | string;
  icon?: string;
  onMore?: () => void;
  children?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  onCollapse?: () => void;
  headerLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | PageTitle;
}

interface State {
  collapsed: boolean;
}

const ICON_TYPES = {
  alert: 'alert',
  warning: 'boost',
  goal: 'target',
};

// TODO: remove and replace once PageTitle component is migrated to TS
interface PageTitle {
  type: {
    displayName: string;
  };
  props: any;
  key: any;
  // tslint:disable-next-line: no-misused-new
  new (): PageTitle;
}

export default class CommonHeader extends React.Component<Props, State> {
  static defaultProps = {
    icon: null,
    onMore: null,
    children: null,
    className: null,
    titleClassName: '',
    onCollapse: null,
    headerLevel: null,
  };

  state = {
    collapsed: false,
  };

  toggle = () => {
    const { onCollapse } = this.props;
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }));
    if (typeof onCollapse === 'function') {
      onCollapse();
    }
  };

  render() {
    const {
      type,
      title,
      titleClassName,
      icon,
      onMore,
      children,
      className,
      onCollapse,
      headerLevel,
    } = this.props;
    const { collapsed } = this.state;

    let HeaderComponent: Props['headerLevel'] | 'div' = headerLevel || 'h2';
    if (typeof title !== 'string') {
      HeaderComponent = 'div';
    }
    return (
      <div className={cx(s[type], className)}>
        {Object.keys(ICON_TYPES).includes(type) ? <Icon icon={ICON_TYPES[type]} /> : null}
        {typeof icon === 'string' && <Icon icon={icon} />}
        {title && (
          <HeaderComponent className={cx(s.headerComponent, 'h3', titleClassName)}>
            <span className="visuallyhidden">{ICON_TYPES[type]} </span>
            {type === 'purple' ? (
              <span className={s.srr_header_text}>My Student Readiness Reports</span>
            ) : (
              title
            )}
          </HeaderComponent>
        )}
        {typeof onMore === 'function' ? (
          <Button variation="noChrome" onClick={onMore} className={s.more}>
            MORE <Icon icon="more" />
          </Button>
        ) : null}
        {typeof onCollapse === 'function' ? (
          <Button variation="noChrome" onClick={this.toggle} className={s.collapsable}>
            Show {collapsed ? 'More' : 'Less'}{' '}
            <Icon icon={collapsed ? 'chevron-down' : 'chevron-up'} />
          </Button>
        ) : null}
        {children}
      </div>
    );
  }
}
