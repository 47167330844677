import {
  addMfeEventListener,
  dispatchMfeEvent,
  initializeMfe,
  setTranslations,
} from '@ps-refarch-ux/mfe-utils';
import { NeonButton } from '@ps-refarch-ux/neon';
import * as React from 'react';
import s from '../../styles.scss';
export interface AceChatbotProps {
  mfeUrl: string;
  mfeApiPrefix: string;
  productCode: string;
  districtId: string;
  buildingId: string;
  studentId: string;
  studentName: string;
  gradeLevel: string;
  sessionId: string;
  topic: string;
  translations: { [key: string]: string };
  language: string;
  authToken: string;
}

interface MfeConfig {
  mfeApiPrefix: string;
  productCode: string;
  districtId: string;
  buildingId: string;
  studentId: string;
  studentName: string;
  gradeLevel: string;
  sessionId: string;
  topic: string;
  language: string;
}

enum MFE_CONFIG {
  NAME = 'ps_mfe_ph_ai_chatbot',
  MODULE = './PhAiChatBot',
}

const MFE_ELEMENT_ID = 'ph-ai-chatbot';
const HOST_EVENT_OPEN_REQUESTED = '!host.chatbot-open-requested';
const MFE_EVENT_CLOSE_REQUESTED = '!ph-ai-chatbot.close-requested';
const MFE_EVENT_SESSION_TOKEN_ISSUED = 'ph-ai-chatbot.session-token-issued';

/**
 * Loads and initializes MFE
 */
async function loadMfe(
  mfeUrl: string,
  mfeConfig: MfeConfig,
  translations: { [key: string]: string }
): Promise<void> {
  setTranslations(translations);
  const locale = mfeConfig.language;

  const element = document.getElementById(MFE_ELEMENT_ID) as HTMLElement;

  try {
    await initializeMfe({
      element,
      remote: MFE_CONFIG.NAME,
      module: MFE_CONFIG.MODULE,
      url: mfeUrl,
      locale,
      config: mfeConfig,
    });
  } catch (error) {
    // handle errors
  }
}

/**
 * App Component renders Mfe on click of button
 */
export function AceChatbot(props: AceChatbotProps): JSX.Element {
  const [disableButton, setDisableButton] = React.useState(false);
  const [loadedMfe, setLoadedMfe] = React.useState(false);
  const [sessionToken, setSessionToken] = React.useState('');

  React.useEffect(() => {
    const {
      buildingId,
      districtId,
      gradeLevel,
      language,
      mfeApiPrefix,
      mfeUrl,
      productCode,
      studentId,
      studentName,
      sessionId,
      topic,
      translations,
      authToken,
    } = props;
    setSessionToken(authToken);
    const mfeConfig: MfeConfig = {
      buildingId,
      districtId,
      gradeLevel,
      language,
      mfeApiPrefix,
      productCode,
      sessionId,
      studentId,
      studentName,
      topic,
    };
    (async (): Promise<void> => {
      await loadMfe(mfeUrl, mfeConfig, translations);
      setLoadedMfe(true);
    })();

    /* istanbul ignore next */
    const unsubscribeX = addMfeEventListener(MFE_EVENT_CLOSE_REQUESTED, () => {
      setDisableButton(false);
      // enableBodyScroll();
    });

    return unsubscribeX;
  }, []);

  const handleClick = (): void => {
    setDisableButton(true);
    dispatchMfeEvent(MFE_CONFIG.NAME, MFE_EVENT_SESSION_TOKEN_ISSUED, sessionToken);
    dispatchMfeEvent(MFE_CONFIG.NAME, HOST_EVENT_OPEN_REQUESTED, {});
  };

  return (
    <>
      {loadedMfe && (
        <div className={s.floatingIcon}>
          <NeonButton
            id="ph-ai-chatbot-container-launch-btn"
            dataText="Launch Powerbuddy"
            disabled={disableButton}
            onClick={handleClick}
          />
        </div>
      )}
      <div id={MFE_ELEMENT_ID} />
    </>
  );
}
