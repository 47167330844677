import { localOpportunitiesUrl } from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import session from 'config/session';
import ls from 'utils/localStorage';
import jwtDecode from 'jwt-decode';

import { injectAsyncReducers } from 'store';

const generateConstants = constantsGenerator('fc/localOpportunities');

const [
  LOCAL_OPPORTUNITIES,
  LOCAL_OPPORTUNITIES_SUCCESS,
  LOCAL_OPPORTUNITIES_FAIL,
] = generateConstants('LOCAL_OPPORTUNITIES');

const initialState = {
  loading: false,
  isLocalOpportunityExist: false,
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOCAL_OPPORTUNITIES:
      return {
        ...state,
        loading: true,
      };
    case LOCAL_OPPORTUNITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLocalOpportunityExist: action.result.payload,
      };
    }
    case LOCAL_OPPORTUNITIES_FAIL: {
      return {
        ...state,
        loading: false,
        isLocalOpportunityExist: action.result.payload,
      };
    }
    default:
      return state;
  }
}

function doInitiativesExist() {
  const jwt = session.isValid() ? ls.getItem('deepLinkingAuthorizedToken') : null;
  if (!jwt) {
    console.error('No JWT found. Initiatives will not be loaded.');
    return Promise.resolve(false);
  }

  const { state } = jwtDecode(jwt);
  if (!state) {
    return Promise.resolve(false);
  }

  return fetch(
    `${localOpportunitiesUrl}/sponsorships/profiles/search?type=initiative&restriction=state%2C${state}`,
    {
      method: 'GET',
      headers: {
        'brapi-token': jwt,
      },
    }
  )
    .then((response) => response.json())
    .then((body) => body.length > 0)
    .catch((err) => {
      console.error(`Getting initiatives failed: ${err.message}`);
      return false;
    });
}

export function localOpportunitiesInitiatives() {
  return (dispatch) =>
    dispatch({
      types: [LOCAL_OPPORTUNITIES, LOCAL_OPPORTUNITIES_SUCCESS, LOCAL_OPPORTUNITIES_FAIL],
      promise: () => doInitiativesExist().then((result) => ({ payload: result })),
    });
}

injectAsyncReducers({ localOpportunities: reducer });
