// @flow
import { createSelector } from 'reselect';
import { isGuestUser } from 'selectors/auth';

export const arePermissionsLoaded = (state: Object): boolean =>
  state.permissions.mainPermissionsLoaded;

export const areAuthPermissionsLoaded = (state: Object): boolean =>
  state.permissions.authPermissionsLoaded;

export const getPermissions = (state: Object): Object => state.permissions.acl;

export const getScattergramPermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) => {
    const collegePermissions = (permissionsLoaded && permissions.resources.colleges) || [];
    return collegePermissions.includes('scattergrams');
  }
);

const getResources = (state: Object): Object => getPermissions(state).resources || {};

export const getPermissionsBranch = (state: Object, branch: string) =>
  getResources(state)[branch] || [];

export const getEditPermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) => (permissionsLoaded && permissions.resources.edit) || []
);

export const getCollegesSearchPermissions = createSelector(
  [getPermissions, arePermissionsLoaded, isGuestUser],
  (permissions, permissionsLoaded, isGuest) => {
    const collegePermissions = (permissionsLoaded && permissions.resources.colleges) || [];
    return !isGuest
      ? collegePermissions.includes('college_search')
      : collegePermissions.includes('college_profiles');
  }
);
