// @flow
import type { AMMatch, LegacyAMMatch } from 'types/colleges';
import pick from 'lodash/pick';

const CHANGE_AM_CONNECTIBLE_MATCHES =
  'fc/colleges/activeMatchConnectionMatching/amConnectibleMatches';
const CHANGE_AM_CONNECTED_MATCH_SCIDS =
  'fc/colleges/activeMatchConnectionMatching/amConnectedMatchScids';
const CHANGE_AM_RECENTLY_CONNECTED_MATCH_SCIDS =
  'fc/colleges/activeMatchConnectionMatching/amRecentlyConnectedMatchScids';
const CHANGE_AM_RECENTLY_DISCONNECTED_MATCH_SCIDS =
  'fc/colleges/activeMatchConnectionMatching/amRecentlyDisconnectedMatchScids';
const CHANGE_DISPLAY_AM_CONNECTOR_POPUP =
  'fc/colleges/activeMatchConnectionMatching/displayAMConnectorPopup';
const CHANGE_DISPLAY_AM_DISCONNECT_POPUP =
  'fc/colleges/activeMatchConnectionMatching/displayAMDisconnectPopup';
const CHANGE_AM_MATCHES_IN_CONNECTOR =
  'fc/colleges/activeMatchConnectionMatching/amMatchesInConnector';
const CHANGE_AM_MATCH_TO_DISCONNECT =
  'fc/colleges/activeMatchConnectionMatching/amMatchesToDisconnect';
const RESET_AM_CONNECTION_MATCHING_STATE =
  'fc/colleges/activeMatchConnectionMatching/resetAmConnectionMatchingState';

const CHANGE_AM_LEGACY_MATCHES_IN_CONNECTOR =
  'fc/colleges/activeMatchConnectionMatching/amLegacyMatchesInConnector';
const CHANGE_AM_LEGACY_MATCH_TO_DISCONNECT =
  'fc/colleges/activeMatchConnectionMatching/amLegacyMatchesToDisconnect';
const CHANGE_IS_CONNECTOR_ENABLED = 'fc/colleges/activeMatchConnectionMatching/isConnectorEnabled';
const CHANGE_IS_CONNECTOR_FOR_LEGACY_ENABLED =
  'fc/colleges/activeMatchConnectionMatching/isConnectorForLegacyEnabled';

export type State = {
  amConnectibleMatches: AMMatch[],
  amConnectedMatchScids: string[],
  amMatchesInConnector: AMMatch[],
  amMatchesToDisconnect: AMMatch[],
  amRecentlyConnectedMatchScids: string[],
  amRecentlyDisconnectedMatchScids: string[],
  displayAMConnectorPopup: boolean,
  displayAMDisconnectPopup: boolean,

  amLegacyMatchesInConnector: LegacyAMMatch[],
  amLegacyMatchesToDisconnect: LegacyAMMatch[],

  isConnectorEnabled: boolean,
  isConnectorForLegacyEnabled: boolean,
};

const initialState: State = {
  amConnectibleMatches: [],
  amConnectedMatchScids: [],
  displayAMConnectorPopup: false,
  displayAMDisconnectPopup: false,
  amMatchesInConnector: [],
  amMatchesToDisconnect: [],
  amRecentlyConnectedMatchScids: [],
  amRecentlyDisconnectedMatchScids: [],
  amLegacyMatchesInConnector: [],
  amLegacyMatchesToDisconnect: [],
  isConnectorEnabled: true,
  isConnectorForLegacyEnabled: true,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case CHANGE_AM_CONNECTIBLE_MATCHES:
      return {
        ...state,
        amConnectibleMatches: action.amConnectibleMatches,
      };
    case CHANGE_AM_CONNECTED_MATCH_SCIDS:
      return {
        ...state,
        amConnectedMatchScids: action.amConnectedMatchScids,
      };
    case CHANGE_AM_RECENTLY_CONNECTED_MATCH_SCIDS:
      return {
        ...state,
        amRecentlyConnectedMatchScids: action.amRecentlyConnectedMatchScids,
      };
    case CHANGE_AM_RECENTLY_DISCONNECTED_MATCH_SCIDS:
      return {
        ...state,
        amRecentlyDisconnectedMatchScids: action.amRecentlyDisconnectedMatchScids,
      };
    case CHANGE_DISPLAY_AM_CONNECTOR_POPUP:
      return {
        ...state,
        displayAMConnectorPopup: action.displayAMConnectorPopup,
      };
    case CHANGE_DISPLAY_AM_DISCONNECT_POPUP:
      return {
        ...state,
        displayAMDisconnectPopup: action.displayAMDisconnectPopup,
      };
    case CHANGE_AM_MATCHES_IN_CONNECTOR:
      return {
        ...state,
        amMatchesInConnector: action.amMatchesInConnector,
      };
    case CHANGE_AM_MATCH_TO_DISCONNECT:
      return {
        ...state,
        amMatchesToDisconnect: action.amMatchesToDisconnect,
      };
    case CHANGE_AM_LEGACY_MATCHES_IN_CONNECTOR:
      return {
        ...state,
        amLegacyMatchesInConnector: action.amLegacyMatchesInConnector,
      };
    case CHANGE_AM_LEGACY_MATCH_TO_DISCONNECT:
      return {
        ...state,
        amLegacyMatchesToDisconnect: action.amLegacyMatchesToDisconnect,
      };
    case CHANGE_IS_CONNECTOR_ENABLED:
      return {
        ...state,
        isConnectorEnabled: action.isConnectorEnabled,
      };
    case CHANGE_IS_CONNECTOR_FOR_LEGACY_ENABLED:
      return {
        ...state,
        isConnectorForLegacyEnabled: action.isConnectorForLegacyEnabled,
      };
    case RESET_AM_CONNECTION_MATCHING_STATE:
      return {
        ...state,
        ...pick(initialState, [
          'amConnectibleMatches',
          'amConnectedMatchScids',
          'displayAMConnectorPopup',
          'displayAMDisconnectPopup',
          'amMatchesInConnector',
          'amMatchesToDisconnect',
          'amRecentlyConnectedMatchScids',
          'amRecentlyDisconnectedMatchScids',
          'isConnectorForLegacyEnabled',
          'isConnectorEnabled',
        ]),
      };
    default:
      return state;
  }
}

export const changeAmConnectibleMatches = (amConnectibleMatches: AMMatch[]) => ({
  type: CHANGE_AM_CONNECTIBLE_MATCHES,
  amConnectibleMatches,
});

export const changeAmConnectedMatchScids = (amConnectedMatchScids: string[]) => ({
  type: CHANGE_AM_CONNECTED_MATCH_SCIDS,
  amConnectedMatchScids,
});

export const changeAmRecentlyConnectedMatchScids = (amRecentlyConnectedMatchScids: string[]) => ({
  type: CHANGE_AM_RECENTLY_CONNECTED_MATCH_SCIDS,
  amRecentlyConnectedMatchScids,
});

export const changeAmRecentlyDisconnectedMatchScids = (
  amRecentlyDisconnectedMatchScids: string[]
) => ({
  type: CHANGE_AM_RECENTLY_DISCONNECTED_MATCH_SCIDS,
  amRecentlyDisconnectedMatchScids,
});

export const changeDisplayAMConnectorPopup = (displayAMConnectorPopup: boolean) => ({
  type: CHANGE_DISPLAY_AM_CONNECTOR_POPUP,
  displayAMConnectorPopup,
});

export const changeDisplayAMDisconnectPopup = (displayAMDisconnectPopup: boolean) => ({
  type: CHANGE_DISPLAY_AM_DISCONNECT_POPUP,
  displayAMDisconnectPopup,
});

export const changeAmMatchesInConnector = (amMatchesInConnector: AMMatch[]) => ({
  type: CHANGE_AM_MATCHES_IN_CONNECTOR,
  amMatchesInConnector,
});

export const changeAmMatchesToDisconnect = (amMatchesToDisconnect: AMMatch[]) => ({
  type: CHANGE_AM_MATCH_TO_DISCONNECT,
  amMatchesToDisconnect,
});

export const changeAmLegacyMatchesInConnector = (amLegacyMatchesInConnector: LegacyAMMatch[]) => ({
  type: CHANGE_AM_LEGACY_MATCHES_IN_CONNECTOR,
  amLegacyMatchesInConnector,
});

export const changeAmLegacyMatchesToDisconnect = (
  amLegacyMatchesToDisconnect: LegacyAMMatch[]
) => ({
  type: CHANGE_AM_LEGACY_MATCH_TO_DISCONNECT,
  amLegacyMatchesToDisconnect,
});

export const changeIsConnectorEnabled = (isConnectorEnabled: boolean) => ({
  type: CHANGE_IS_CONNECTOR_ENABLED,
  isConnectorEnabled,
});

export const changeIsConnectorForLegacyEnabled = (isConnectorForLegacyEnabled: boolean) => ({
  type: CHANGE_IS_CONNECTOR_FOR_LEGACY_ENABLED,
  isConnectorForLegacyEnabled,
});

export const resetAmConnectionMatchingState = () => ({
  type: RESET_AM_CONNECTION_MATCHING_STATE,
});
