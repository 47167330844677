// @flow
import { createSelector } from 'reselect';
import type { CollegeLookingForStudentsLikeMe } from 'types/colleges';
import { combineWithNaturalLanguage } from 'utils/combineStrings';
import type { State } from 'modules/colleges/lookingForStudentsLikeYou';

export const getCollegesLookingForStudentsLikeMeState = (state: Object): State =>
  state.colleges.lookingForStudentsLikeYou;

export const getMatchingColleges = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): CollegeLookingForStudentsLikeMe[] =>
    lookingForStudentsLikeMeState.matchingColleges
);

export const getUnfilteredMatchingColleges = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): CollegeLookingForStudentsLikeMe[] =>
    lookingForStudentsLikeMeState.unfilteredMatchingColleges
);

export const getCollegesForPopup = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): CollegeLookingForStudentsLikeMe[] =>
    lookingForStudentsLikeMeState.collegesInPopup
);

export const getDisplayPopup = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): boolean =>
    lookingForStudentsLikeMeState.displayConnectorPopup
);

export const getCollegesToDisconnectFrom = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): CollegeLookingForStudentsLikeMe[] =>
    lookingForStudentsLikeMeState.collegesToDisconnect
);

export const getCollegesToDisconnectNames = createSelector(
  [getCollegesToDisconnectFrom],
  (collegesToDisconnectFrom: CollegeLookingForStudentsLikeMe[]) =>
    combineWithNaturalLanguage(collegesToDisconnectFrom.map((college) => college.name))
);

export const getDisplayDisconnectPopup = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): boolean =>
    lookingForStudentsLikeMeState.displayDisconnectPopup
);

export const getCollegesWithConnectorForPopup = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): Object[] =>
    lookingForStudentsLikeMeState.collegesWithConnector
);

export const getMatchingCollegeToDisconnect = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: State): Object[] =>
    lookingForStudentsLikeMeState.matchingCollegesToDisconnect
);

export const isLoading = createSelector(
  [getCollegesLookingForStudentsLikeMeState],
  (lookingForStudentsLikeMeState: Object): boolean => lookingForStudentsLikeMeState.loading
);
