/**
 * Returns a patched React module that will be compatible with libraries that
 * depend on `useSyncExternalstore` introduced in React 18.
 * At the time of writing, this repo uses React 17, which requires shimming.
 * Once this repo upgrades to React 18, this function will be a no-op, and
 * the shim can be removed.
 */
export const withReactCompatibilityShim = (React: any) => {
  if (!React.useSyncExternalStore) {
    // lazily import library to avoid including it in bundles where it is not required
    const { useSyncExternalStore } = require('use-sync-external-store/shim');
    return {
      ...React,
      useSyncExternalStore,
    };
  }
  return React;
};
